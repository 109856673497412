import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { Heading } from 'theme-ui'

const ErrorNotFoundPage = () => (
  <Wrapper>
    <Heading as="h1" variant="primaryMedium">
      404 Page Not Found
    </Heading>
    <p>
      The page you requested does not exist. Click
      <Link to="/collections/">here</Link>
      to continue shopping.
    </p>
  </Wrapper>
)

export default ErrorNotFoundPage

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  padding: 64px 24px;
  text-align: center;

  h1 {
    margin-bottom: 16px;
  }

  a {
    margin: 0 5px;
    text-decoration: underline;
  }
`
