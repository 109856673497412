import React from 'react'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Metadata, Page } from '~/components/Layout'
import ErrorNotFoundPage from '~/components/Error/NotFound/Page'

const NotFound = () => {
  const translate = useTranslate()

  return (
    <Page>
      <Metadata
        title={translate('error.not_found.page_title')}
        description="The page you requested does not exist"
      />
      <ErrorNotFoundPage />
    </Page>
  )
}

export default NotFound
